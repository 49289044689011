<template>
  <v-card
    tile
    class="mx-auto"
  >
    <v-card-text class="pt-0 px-4">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="bookingCustomerName"
              :label="$t('name')"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="bookingCustomerPhone"
              :label="$t('phone')"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="bookingCustomerEmail"
              :label="$t('email')"
              hide-details
              outlined
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="pb-2"
              >
                <v-select
                  detai
                  :label="$t('bookedItem')"
                  :disabled="processing"
                  :items="itemsFilteredByLocation"
                  v-model="booking.item.uuid"
                  item-value="uuid"
                  item-text="title"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="pb-2"
              >
                <v-select
                  hide-details
                  v-if="locations !== null && locations.length > 1"
                  :label="$t('location')"
                  outlined
                  v-model="selectedLocation"
                  :items="locations"
                  item-value="uuid"
                  item-text="label"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="pb-2"
              >
                <v-select
                  :label="$t('tables')"
                  :disabled="processing"
                  :items="locationVariations"
                  v-model="bookingTables"
                  clearable
                  item-value="uuid"
                  item-text="label"
                  hide-details
                  outlined
                  multiple
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="pb-2"
              >
                <v-menu
                  :disabled="processing"
                  ref="selectDate"
                  :return-value.sync="startDate"
                  v-model="dateMenu"
                  :close-on-content-click="true"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{on, attr}">
                    <v-text-field
                      readonly
                      :label="$t('date')"
                      outlined
                      v-model="startDate"
                      :placeholder="$t('pleaseSelectADate')"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attr"
                      v-on="on"
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    v-if="dateMenu"
                    no-title
                    @click:date="$refs.selectDate.save(startDate)"
                    style="min-height: 350px"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="pb-6"
              >
                <v-select
                  :label="$t('quantity')"
                  :disabled="processing"
                  :items="itemQuantity"
                  v-model="booking.quantity"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-menu
                  :disabled="processing"
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      large
                      outlined
                      v-model="startDateAndTime"
                      :label="$t('arrival')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <TimeSelector
                    v-model="startDateAndTime"
                    @selectTime="selectTime"
                    no-title
                    :time="startDateAndTime"
                    @close="menu2 = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  hide-details
                  :disabled="processing"
                  large
                  outlined
                  :label="$t('duration')"
                  v-model="durationInMinutes"
                  :suffix="$t('minutesShort')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col
                cols="12"
                lg="12"
              >
                <v-textarea
                  :label="$t('externalNote')"
                  :disabled="processing"
                  v-model="booking.externalNote"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                lg="12"
              >
                <v-textarea
                  :label="$t('internalNote')"
                  :disabled="processing"
                  v-model="booking.internalNote"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                lg="12"
              >
                <v-card>
                  <v-card-text
                    class="font-weight-bold"
                    v-if="channelExists"
                  >
                    {{ $t('bookingChannel') }}: {{ this.channel.label }}
                  </v-card-text>
                  <v-card-text
                    class="font-weight-bold"
                    v-else
                  >
                    {{ $t('bookingCreatedInBackend') }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-row v-if="Array.isArray(booking.upsellLineItems) && booking.upsellLineItems.length > 0">
          <v-col
            cols="12"
            class="pb-0"
          >
            {{ $t('upsell') }}
          </v-col>
          <template v-for="(lineItem, lineItemIndex) in booking.upsellLineItems">
            <v-col
              cols="12"
              :key="`upsell-${lineItemIndex}`"
            >
              <v-card
                style="font-weight: 700; font-size: 26px;"
                :color="`teal darken-${colorNumber(lineItemIndex)}`"
                dark
              >
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="2">
                      {{ `${lineItem.quantity}x` }}
                    </v-col>
                    <v-col cols="auto">
                      {{ lineItem.label }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-divider v-if="Array.isArray(booking.upsellLineItems) && booking.upsellLineItems.length > 0" />
        <v-row v-if="organizationIsSmarabio && waiver && waiver.data">
          <template v-for="field in smarabioFields">
            <v-col
              :cols="field.cols"
              :key="field.key"
            >
              <v-text-field
                v-if="field.type === 'text'"
                :disabled="processing"
                outlined
                hide-details
                :label="field.label"
                v-model="waiver.data[field.key]"
              />
              <v-textarea
                v-if="field.type === 'textarea'"
                :disabled="processing"
                outlined
                hide-details
                :label="field.label"
                v-model="waiver.data[field.key]"
              />
            </v-col>
          </template>
        </v-row>
        <v-divider v-if="organizationIsSmarabio && waiver && waiver.data" />
        <v-row v-if="checkoutSectionInstancesData.length > 0">
          <v-col
            v-for="(checkoutSectionInstance, index) in checkoutSectionInstancesData"
            :key="index"
            cols="12"
          >
            <div v-if="checkoutSectionInstance.namespace !== 'billing_minimal' && checkoutSectionInstance.data">
              <p class="text-subtitle-1 mb-2">
                {{ thingTitle(checkoutSectionInstance.namespace) }}
              </p>
              <p
                v-for="(thing, index2) in checkoutSectionInstance.data"
                :key="index2"
              >
                <v-text-field
                  v-if="index2 !== 'disclaimer' && typeof thing === 'string'"
                  hide-details
                  readonly
                  outlined
                  :label="thingTitle(index2)"
                  :value="thing"
                />
                <v-switch
                  v-if="index2 !== 'disclaimer' && typeof thing === 'boolean'"
                  readonly
                  hide-details
                  outlined
                  :label="thingTitle(index2)"
                  :value="thing"
                />
              </p>
            </div>
          </v-col>
        </v-row>
        <v-divider v-if="checkoutSectionInstancesData.length > 0" />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container class="py-0">
        <v-row align="center">
          <v-col
            class="pt-0"
            cols="12"
            md="2"
          >
            <v-btn
              large
              block
              color="primary"
              @click="showNotifyCustomerDialog = true"
              :disabled="processing || booking.status === false || booking.status === '0'"
            >
              {{ $t('update') }}
            </v-btn>
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            md="2"
          >
            <v-btn
              large
              block
              color="indigo"
              class="white--text"
              @click="rebook"
              :disabled="processing || booking.status === false || booking.status === '0'"
            >
              {{ $t('rebook') }}
            </v-btn>
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            md="2"
          >
            <v-btn
              large
              block
              color="info"
              @click="duplicateBooking"
              :processing="processingDuplicate"
              :disabled="processing || booking.status === false || booking.status === '0'"
            >
              {{ $t('duplicate') }}
            </v-btn>
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            md="3"
          >
            <v-btn
              large
              block
              color="green"
              :loading="checkingInBooking"
              @click="redeemBooking"
              :disabled="processing || booking.status === false || booking.status === '0'"
            >
              {{ $t('checkIn') }}
            </v-btn>
          </v-col>
          <v-col
            class="pt-0"
            cols=""
            md="3"
          >
            <v-btn
              v-if="booking.status === true ||booking.status ==='1'"
              large
              block
              color="error"
              @click="clickCancel"
              :disabled="processing"
            >
              {{ $t('cancelBooking') }}
            </v-btn>
            <v-btn
              v-if="booking.status === false ||booking.status ==='0'"
              large
              block
              color="primary"
              @click="clickActivate"
              :disabled="processing"
            >
              {{ $t('activateBooking') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
    <v-dialog
      v-if="cancelBookingAction"
      v-model="cancelBookingAction"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('cancelBooking?') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('areYouSureCancelBooking?') }}
        </v-card-subtitle>
        <v-card-subtitle
          v-if="cancelError"
          class="error--text pb-0"
        >
          {{ cancelError }}
        </v-card-subtitle>
        <v-card-actions>
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                block
                large
                @click="clickCancel"
                :disabled="processing"
              >
                {{ $t('goBack') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                block
                large
                @click="cancelBookingMethod"
                :loading="processing"
                :disabled="processing"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="activateBookingAction"
      v-model="activateBookingAction"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('activateBooking?') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('areYouSureYouWantToActivateBooking') }}
        </v-card-subtitle>
        <v-card-subtitle
          v-if="activateError"
          class="error--text pb-0"
        >
          {{ activateError }}
        </v-card-subtitle>
        <v-card-actions>
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                block
                large
                @click="goBack"
                :disabled="processing"
              >
                {{ $t('goBack') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                block
                large
                @click="activateBookingMethod"
                :loading="processing"
                :disabled="processing"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="changeBookingAction"
      v-model="changeBookingAction"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('changeBooking?') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('areYouSureYouWantToChangeBooking') }}
        </v-card-subtitle>
        <v-card-subtitle
          v-if="changeError"
          class="error--text pb-0"
        >
          {{ changeError }}
        </v-card-subtitle>
        <v-card-actions>
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                block
                large
                @click="goBack"
                :disabled="processing"
              >
                {{ $t('goBack') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                block
                large
                @click="changeBookingMethod"
                :loading="processing"
                :disabled="processing"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NotifyCustomerDialog
      v-if="showNotifyCustomerDialog"
      :processing="processing"
      :booking="booking"
      @changeBookingMethod="changeBookingMethod"
    />
  </v-card>
</template>

<script>
import NotifyCustomerDialog from "./NotifyCustomerDialog"
import TimeSelector from "../common/TimeSelector"
import moment from "moment"

/**
 * @property {String} startsAtTime
 * @property {String} endsAtTime
 */
export default {
  name: "BookingCard",
  components: {
    NotifyCustomerDialog,
    TimeSelector
  },
  props: {
    booking: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      checkingInBooking: false,
      cancelBookingAction: false,
      activateBookingAction: false,
      changeBookingAction: false,
      cancelError: null,
      activateError: null,
      changeError: null,
      processing: false,
      modal: false,
      locationVariations: [],
      menu2: false,
      dateMenu: false,
      orderObject: {},
      showNotifyCustomerDialog: false,
      processingDuplicate: false,
      channel: {}
    }
  },
  beforeMount() {
    if(this.booking.order !== null && this.booking.order !== undefined && this.booking.order !== '') {
      this.$store.dispatch('getOrderByUUID', {uuid: this.booking.order}).then(bookingObject => {

        if(bookingObject === null || bookingObject === undefined) {
          return null
        }

        // Parsing data
        if(bookingObject.data !== null && bookingObject.data !== undefined) {
          if(Array.isArray(bookingObject.data.checkoutSectionInstancesData)) {
            for(let i = 0; i < bookingObject.data.checkoutSectionInstancesData.length; i++) {
              let instanceData = bookingObject.data.checkoutSectionInstancesData[i]
              if(instanceData !== null && instanceData !== undefined) {
                if(instanceData.data !== null && instanceData.data !== undefined && typeof instanceData.data === 'string') {
                  try {
                    instanceData.data = JSON.parse(instanceData.data)
                  } catch (e) {
                    //
                  }
                }
              }
            }
          }
        }

        if(this.organizationIsSmarabio) {
          // check if order object has the necessary fields to be filled out - if not, add them
          if(bookingObject.data === null || bookingObject.data === undefined) {
            bookingObject.data = {}
          }
          if(!Array.isArray(bookingObject.data.checkoutSectionInstancesData)) {
            bookingObject.data.checkoutSectionInstancesData = []
          }
          const waiver = bookingObject.data.checkoutSectionInstancesData.find(instance => instance.namespace === 'waiver')
          if(waiver === null || waiver === undefined) {
            bookingObject.data.checkoutSectionInstancesData.push({
              namespace: 'waiver',
              data: {
                "waiver_child_nafn": null,
                "waiver_child_age": null,
                "waiver_child_kennitala": null,
                "waiver_child_ofnæmi": null,
                "waiver_child_name_of_parent_1": null,
                "waiver_child_simanumer_1": null,
                "waiver_child_netfang_1": null,
                "waiver_child_name_of_parent_2": null,
                "waiver_child_simanumer_2": null,
                "waiver_child_netfang_2": null,
                "waiver_child_heimilisfang": null,
                "waiver_child_gotuheiti": null,
                "waiver_sveitafelag": null,
                "waiver_postal_code": null
              }})
          } else if(waiver.data === null || waiver.data === undefined) {
            waiver.data = {
              "waiver_child_nafn": null,
              "waiver_child_age": null,
              "waiver_child_kennitala": null,
              "waiver_child_ofnæmi": null,
              "waiver_child_name_of_parent_1": null,
              "waiver_child_simanumer_1": null,
              "waiver_child_netfang_1": null,
              "waiver_child_name_of_parent_2": null,
              "waiver_child_simanumer_2": null,
              "waiver_child_netfang_2": null,
              "waiver_child_heimilisfang": null,
              "waiver_child_gotuheiti": null,
              "waiver_sveitafelag": null,
              "waiver_postal_code": null
            }
          }
        }
        this.orderObject = bookingObject
      })
    }
  },
  mounted() {
    if(typeof this.booking !== 'undefined' && this.booking !== null) {
      let locationUUID = null
      if(typeof this.booking.location !== 'undefined' && this.booking.location !== null && typeof this.booking.location.uuid !== 'undefined') {
        locationUUID = this.booking.location.uuid
      }
      const payload = {
        location: locationUUID,
        startTimeInMillis: Math.round(this.booking.startsAtTime),
        endTimeInMillis: Math.round(this.booking.endsAtTime)
      }

      this.selectedLocation = locationUUID

      this.processing = false
      this.locationVariations = this.selectedLocationObject.variations
      /*
      this.$store.dispatch('listAvailableLocationVariations', payload).then(response => {
        this.locationVariations = response
        if(this.booking.locationVariation !== null && this.booking.locationVariation !== undefined) {
          this.locationVariations.push(this.booking.locationVariation)
        }

        if(this.booking.locationVariations !== null && this.booking.locationVariations !== undefined && this.booking.locationVariations.length > 0) {
          for(let i in this.booking.locationVariations) {
            const locationVariation = this.booking.locationVariations[i]
            this.locationVariations.push(locationVariation)
          }
        }
        this.locationVariations.sort((a, b) => a.label.localeCompare(b.label))
      }).finally(() => {
        this.processing = false
      })
      */
    }
  },
  computed: {

    smarabioFields() {
      return [
        {
          key: 'waiver_child_nafn',
          type: 'text',
          label: 'Nafn barns',
          required: true,
          weight: 9,
          cols: 4
        },
        {
          key: 'waiver_child_age',
          type: 'text',
          label: 'Aldur barns',
          required: true,
          weight: 10,
          cols: 4
        },
        {
          key: 'waiver_child_kennitala',
          type: 'text',
          label: 'Kennitala barns',
          required: true,
          weight: 11,
          cols: 4
        },
        {
          key: 'waiver_child_ofnæmi',
          type: 'textarea',
          label: 'Hvort barn sé með ofnæmi eða greiningu',
          required: true,
          weight: 12,
          cols: 12
        },
        {
          key: 'waiver_child_name_of_parent_1',
          type: 'text',
          label: 'Nafn foreldris/forráðamanns #1',
          required: true,
          weight: 13,
          cols: 4
        },
        {
          key: 'waiver_child_simanumer_1',
          type: 'text',
          label: 'Símanúmer #1',
          required: true,
          weight: 14,
          cols: 4
        },
        {
          key: 'waiver_child_netfang_1',
          type: 'text',
          label: 'Netfang #1',
          required: true,
          weight: 15,
          cols: 4
        },
        {
          key: 'waiver_child_name_of_parent_2',
          type: 'text',
          label: 'Nafn foreldris/forráðamanns #2',
          required: true,
          weight: 16,
          cols: 4
        },
        {
          key: 'waiver_child_simanumer_2',
          type: 'text',
          label: 'Símanúmer #2',
          required: true,
          weight: 17,
          cols: 4
        },
        {
          key: 'waiver_child_netfang_2',
          type: 'text',
          label: 'Netfang #2',
          required: true,
          weight: 18,
          cols: 4
        },
        {
          key: 'waiver_child_heimilisfang',
          type: 'text',
          label: 'Heimilisfang',
          required: true,
          weight: 19,
          cols: 6
        },
        {
          key: 'waiver_child_gotuheiti',
          type: 'text',
          label: 'Götuheiti',
          required: true,
          weight: 20,
          cols: 6
        },
        {
          key: 'waiver_sveitafelag',
          type: 'text',
          label: 'Sveitafélag',
          required: true,
          weight: 21,
          cols: 6
        },
        {
          key: 'waiver_postal_code',
          type: 'text',
          label: 'Póstnúmer',
          required: true,
          weight: 22,
          cols: 6
        }
      ]
    },
    organizationIsSmarabio() {
      const organization = this.$store.getters.organization
      if(organization !== null && organization !== undefined && organization.uuid !== null && organization.uuid !== undefined) {
        // 5357c52b-8c9e-46b1-81c0-a093f2da7233 is Demo organization
        return organization.uuid === '4e507c89-2141-408a-9878-a8dcdb5b0cec' || organization.uuid === '5357c52b-8c9e-46b1-81c0-a093f2da7233'
      }
      return false
    },
    organizationIsPerlan() {
      const organization = this.$store.getters.organization
      if(organization !== null && organization !== undefined && organization.uuid !== null && organization.uuid !== undefined) {
        // 5357c52b-8c9e-46b1-81c0-a093f2da7233 is Demo organization
        return organization.uuid === '0612810a-43d8-4adf-a8df-f2098286ca26' || organization.uuid === 'eae77cfa-23ae-40c5-95d5-632d37541204'
      }
      return false
    },
    startDate: {
      get() {
        let date = new Date(this.booking.startsAtTime * 1000)
        return date.toISOString().substr(0,10)
      },
      set(value) {
        let split = value.split('-')
        let timeString = new Date(this.booking.startsAtTime * 1000).setFullYear(split[0]*1, (split[1]*1)-1, split[2]*1)
        let duration = this.durationInMinutes
        this.booking.startsAtTime = timeString/1000
        this.durationInMinutes = duration
      }
    },
    startDateAndTime: {
      get() {
        let date = new Date(this.booking.startsAtTime * 1000)
        return date.toISOString().substr(11,5)
      },
      set(value) {
        let time = value.split(':')
        let timeString = new Date(this.booking.startsAtTime * 1000).setHours(time[0] * 1, time[1] * 1,0,0)
        let duration = this.durationInMinutes
        this.booking.startsAtTime = timeString/1000
        this.durationInMinutes = duration
      }
    },
    checkoutSectionInstancesData() {
      if(this.orderObject !== null && this.orderObject !== undefined && this.orderObject.data !== null && this.orderObject.data !== undefined) {
        if(Array.isArray(this.orderObject.data.checkoutSectionInstancesData)) {
          return this.orderObject.data.checkoutSectionInstancesData
        }

        try {
          let dataParsed = JSON.parse(this.orderObject.data)
          if(dataParsed.checkoutSectionInstancesData !== null && dataParsed.checkoutSectionInstancesData !== undefined) {
            dataParsed.checkoutSectionInstancesData.forEach(checkoutSectionInstance => {
              if(checkoutSectionInstance.data !== null && checkoutSectionInstance.data !== undefined) {
                checkoutSectionInstance.dataParsed = Object.entries(JSON.parse(checkoutSectionInstance.data))
              }
            })
            return dataParsed.checkoutSectionInstancesData
          }
          return []
        } catch (e) {
          if(Array.isArray(this.orderObject.data.checkoutSectionInstancesData)) {
            return this.orderObject.data.checkoutSectionInstancesData
          }
          return []
        }
      }
      return []
    },
    itemQuantity() {

      const options = []

      for(let i = 1; i < 99; i++) {
        options.push(i)
      }

      return options
    },
    durationInMinutes: {
      get() {
        let minutes = (this.booking.endsAtTime + 1) - this.booking.startsAtTime
        return Math.round(minutes/60)
      },
      set(value) {
        let minutesAsSeconds = value * 60
        this.booking.endsAtTime = (this.booking.startsAtTime + minutesAsSeconds) - 1
      }
    },
    bookingCustomerName: {
      get() {
        if(this.booking && this.booking.customer && this.booking.customer.address && this.booking.customer.address.name_line) {
          return this.booking.customer.address.name_line
        }
        return null
      },
      set(name) {
        if(!this.booking) { this.booking = {} }
        if(!this.booking.customer) { this.booking.customer = {} }
        if(!this.booking.customer.address) { this.booking.customer.address = {} }
        this.booking.customer.address.name_line = name
      }
    },
    bookingCustomerPhone: {
      get() {
        if(this.booking && this.booking.customer && this.booking.customer.phone) {
          return this.booking.customer.phone
        }
        return null
      },
      set(phone) {
        if(!this.booking) { this.booking = {} }
        if(!this.booking.customer) { this.booking.customer = {} }
        this.booking.customer.phone = phone
      }
    },
    bookingCustomerEmail: {
      get() {
        if(this.booking && this.booking.customer && this.booking.customer.email) {
          return this.booking.customer.email
        }
        return null
      },
      set(email) {
        if(!this.booking) { this.booking = {} }
        if(!this.booking.customer) { this.booking.customer = {} }
        this.booking.customer.email = email
      }
    },
    // bookingFromStore() {
    // 	return this.$store.getters.getBookingByUUID(this.$route.params.uuid)
    // },
    locationVariation: {
      get() {
        if (this.booking.locationVariation) {
          return this.booking.locationVariation.uuid
        }
        return null
      },
      set(value) {
        if(value === null) {
          this.booking.locationVariation = null
        }
        else {
          this.booking.locationVariation = this.locationVariations.find(v => v.uuid === value)
        }
      }
    },
    bookingTables: {
      get() {
        if (this.booking.locationVariations) {
          return this.booking.locationVariations
        }
        return null
      },
      set(values) {
        if(values === null) {
          this.booking.locationVariations = null
        }
        else {
          this.booking.locationVariations = this.locationVariations.filter(v => values.includes(v.uuid))
        }
      }
    },
    hasImages() {
      return !!(this.booking.item && this.booking.item.images && this.booking.item.images.length)
    },
    getImage() {
      if(this.hasImages
        && this.booking.item.images[0].file
        && this.booking.item.images[0].file.sources
        && this.booking.item.images[0].file.sources.original) {
        return this.booking.item.images[0].file.sources.original
      } else {
        return null
      }
    },
    systemTimeInMillis() {
      return this.$store.getters.systemTime
    },
    organizationUUID() {
      return this.$store.state.organization.uuid
    },
    bookingCustomerID() {
      if(this.booking && this.booking.customer && this.booking.customer.customer_id) {
        return this.booking.customer.customer_id
      }
      return null
    },
    waiver() {
      if(this.orderObject !== null && this.orderObject !== undefined) {
        if(this.orderObject.data !== null && this.orderObject.data !== undefined) {
          if(Array.isArray(this.orderObject.data.checkoutSectionInstancesData)) {
            return this.orderObject.data.checkoutSectionInstancesData.find(instance => instance.namespace === 'waiver')
          }
        }
      }
      return {}
    },
    items() {
      return this.$store.state.items.filter(item => item.type === 'event' && item.status === true).sort((p1, p2) => {

        let w1 = 0
        let w2 = 0

        if(p1.title.toLowerCase().includes('borð')) {
          w1 = -99
        }

        if(p2.title.toLowerCase().includes('borð')) {
          w2 = -99
        }

        if(p1.title.toLowerCase().includes('table')) {
          w1 = -99
        }

        if(p2.title.toLowerCase().includes('table')) {
          w2 = -99
        }

        return w1-w2
      })
    },
    selectedLocation: {
      get() {
        return this.$store.state.selectedLocation
      },
      set(value) {
        this.$store.commit('updateSelectedLocation', value)
      }
    },
    selectedLocationObject() {
      return this.locations.find(location => location.uuid === this.selectedLocation)
    },
    locations() {
      return this.$store.state.locations.filter(location => location.label !== null && location.label !== undefined && location.label !== '')
    },
    itemsFilteredByLocation() {
      if(typeof this.selectedLocation !== 'undefined' && this.selectedLocation !== null) {
        return this.items.filter(item => typeof item !== 'undefined' && item !== null
          && ((typeof item.location !== 'undefined' && item.location !== null && item.location.uuid === this.selectedLocation) || typeof item.location === 'undefined' || item.location === null))
      }
      return this.items
    },
    channelExists() {
      if (typeof this.booking !== 'undefined' && this.booking !== null){
        if( typeof this.booking.channel !== 'undefined' && this.booking.channel !== null && this.booking.channel.length > 0) {
          this.getChannel(this.booking.channel)
          return true
        }
        else {
          return false
        }
      }
      else {
        console.log(this.$t('theBookingDoesNotExistOrIsUndefined'))
        return false
      }
    },
    selectedDate() {
      return this.$store.state.selectedDate
    }
  },
  watch: {
    overwriteBooking(newBooking) {
      this.orderObject = null
    }
  },
  methods: {
    colorNumber(index) {
      if(index === 0) {
        return 1
      }
      if(index % 4 === 0) {
        return 4
      }
      if(index % 3 === 0) {
        return 3
      }
      if(index % 2 === 0) {
        return 2
      }
      return 1
    },
    thingTitle(value) {

      if(value === null || value === undefined || typeof value !== 'string') {
        return ''
      }

      value = value.replace(/_/gi, ' ')

      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    thingValue(value) {
      return value
    },
    redeemBooking() {
      this.processing = true
      this.checkingInBooking = true
      this.$store.dispatch('redeemBooking', this.booking).finally(() => {

        if(this.selectedDate) {
          const dateMoment = moment(this.selectedDate)

          const payload = {
            startTimeInMillis: dateMoment.startOf('day').valueOf(),
            endTimeInMillis: dateMoment.endOf('day').valueOf()
          }

          this.$store.dispatch('getBookingsByDate', payload).finally(() => {
            this.processing = false
            this.checkingInBooking = false
            this.$emit('close')
          })
        } else {
          this.$store.dispatch('getBookingsByDate', {
            startTimeInMillis: this.$store.state.startTimeInMillis,
            endTimeInMillis: this.$store.state.endTimeInMillis
          }).finally(() => {
            this.processing = false
            this.checkingInBooking = false
            this.$emit('close')
          })
        }
      })
    },
    goBack() {
      this.activateBookingAction = false
      this.cancelBookingAction = false
      this.changeBookingAction = false
      this.activateError = null
      this.cancelError = null
      this.changeError = null
    },
    clickCancel() {
      this.cancelBookingAction = !this.cancelBookingAction
      this.cancelError = null
    },
    clickActivate() {
      this.activateBookingAction = !this.activateBookingAction
      this.activateError = null
    },
    changeBookingMethod(notifyCustomer) {
      this.processing = true
      this.changeError = null

      if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
        this.activateError = this.$t('unableToChangeBookingDueToMissingIdentifiers')
        return
      }

      const b = {
        uuid: this.booking.uuid,
        item: this.booking.item.uuid,
        customer: this.booking.customer.uuid,
        namespace: this.booking.namespace,
        startsAtTime: this.booking.startsAtTime,
        endsAtTime: this.booking.endsAtTime,
        quantity: this.booking.quantity,
        internalNote: this.booking.internalNote,
        externalNote: this.booking.externalNote,
        contactName: this.booking.customer.address.name_line,
        contactEmail: this.booking.customer.email,
        contactPhone: this.booking.customer.phone,
        locationVariation: this.booking.locationVariation ? this.booking.locationVariation.uuid.toString() : null,
        locationVariations: this.booking.locationVariations ? this.booking.locationVariations.map((v) => {
          return v.uuid + ''
        }) : null,
        notifyCustomer: notifyCustomer
      }

      if(this.organizationIsSmarabio) {

        if(this.orderObject !== null && this.orderObject !== undefined && this.orderObject.uuid !== undefined && this.orderObject.uuid === null) {

          try {
            for(let i = 0; i < this.orderObject.data.checkoutSectionInstancesData.length; i++) {
              this.orderObject.data.checkoutSectionInstancesData[i].data = JSON.stringify(this.orderObject.data.checkoutSectionInstancesData[i].data)
            }
          } catch (e) {
            //
          }

          let payload = {
            uuid: this.orderObject.uuid,
            data: this.orderObject.data
          }
          this.$store.dispatch('updateOrderData', payload).then(() => {})
        }

      }

      this.$store.dispatch('updateBooking', b)
        .then(result => {
          if(result) {
            this.changeBookingAction = false
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('youHaveSuccessfullyChangedTheBooking')
            })
            this.$emit('close')
          } else {
            this.activateError = this.$t('couldNotChangeBooking')
          }
        })
        .finally(() => {
          this.booking = this.$store.getters.getBookingByUUID(this.$route.params.uuid)
          this.processing = false
        })
    },
    duplicateBooking() {
      this.processing = true
      this.processingDuplicate = true

      this.$store.dispatch('createBooking', {
        item: this.booking.item.uuid,
        description: this.$t('bookedFromEpos'),
        namespace: 'event',
        contactName: this.booking.customer.address.name_line,
        contactPhone: this.booking.customer.phone,
        contactEmail: this.booking.customer.email,
        startsAtTime: this.booking.startsAtTime,
        endsAtTime: this.booking.endsAtTime,
        location: this.booking.location.uuid,
        locationVariation: this.booking.locationVariation ? this.booking.locationVariation.uuid : null,
        sendTicket: false,
        internalNote: this.booking.internalNote,
        externalNote: this.booking.externalNote,
        quantity: this.booking.quantity
      }).finally(() => {
        if(this.selectedDate) {
          const dateMoment = moment(this.selectedDate)

          const payload = {
            startTimeInMillis: dateMoment.startOf('day').valueOf(),
            endTimeInMillis: dateMoment.endOf('day').valueOf()
          }

          this.$store.dispatch('getBookingsByDate', payload).finally(() => {
            this.processing = false
            this.checkingInBooking = false
            this.$emit('close')
          })
        } else {
          this.$store.dispatch('getBookingsByDate', {
            startTimeInMillis: this.$store.state.startTimeInMillis,
            endTimeInMillis: this.$store.state.endTimeInMillis
          }).finally(() => {
            this.processing = false
            this.checkingInBooking = false
            this.$emit('close')
          })
        }
      })
    },
    activateBookingMethod() {
      this.processing = true
      this.activateError = null
      if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
        this.activateError = this.$t('unableToActivateBookingDueToMissingIdentifiers')
        return
      }

      const b = {
        uuid: this.booking.uuid,
        item: this.booking.item.uuid,
        customer: this.booking.customer.uuid,
        namespace: this.booking.namespace,
        status: true
      }

      this.$store.dispatch('updateBooking', b)
        .then(result => {
          if(result) {
            this.activateBookingAction = false
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('youHaveSuccessfullyActivatedTheBooking')
            })
            this.$emit('close')
          } else {
            this.activateError = this.$t('couldNotActivateBooking')
          }
        })
        .finally(() => {
          this.booking = this.$store.getters.getBookingByUUID(this.$route.params.uuid)
          this.processing = false
        })
    },
    cancelBookingMethod() {
      this.processing = true

      this.cancelError = null

      if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
        this.cancelError = this.$t('unableToCancelBookingDueToMissingIdentifiers')
        return
      }

      const b = {
        uuid: this.booking.uuid,
        item: this.booking.item.uuid,
        customer: this.booking.customer.uuid,
        namespace: this.booking.namespace,
        status: false
      }

      this.$store.dispatch('updateBooking', b)
        .then(result => {
          if(result) {
            this.cancelBookingAction = false
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('youHaveSuccessfullyCancelledTheBooking')
            })
            this.$emit('close')
          } else {
            this.cancelError = this.$t('couldNotCancelBooking')
          }
        })
        .finally(() => {
          this.booking = this.$store.getters.getBookingByUUID(this.$route.params.uuid)
          this.processing = false
        })
    },
    editBooking() {
      this.changeBookingAction = true
    },
    dateParse(secondString) {
      if(secondString.toString().length === 10) {
        return new Date(secondString * 1000)
      } else {
        return new Date(secondString)
      }
    },
    setSmarabioFields() {
      this.smarabioFields.push({
        key: 'waiver_child_nafn',
        type: 'text',
        label: 'Nafn barns',
        required: true,
        weight: 9,
        cols: 4
      })


      this.smarabioFields.push({
        key: 'waiver_child_age',
        type: 'text',
        label: 'Aldur barns',
        required: true,
        weight: 10,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_kennitala',
        type: 'text',
        label: 'Kennitala barns',
        required: true,
        weight: 11,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_ofnæmi',
        type: 'textarea',
        label: 'Hvort barn sé með ofnæmi eða greiningu',
        required: true,
        weight: 12,
        cols: 12
      })

      this.smarabioFields.push({
        key: 'waiver_child_name_of_parent_1',
        type: 'text',
        label: 'Nafn foreldris/forráðamanns #1',
        required: true,
        weight: 13,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_simanumer_1',
        type: 'text',
        label: 'Símanúmer #1',
        required: true,
        weight: 14,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_netfang_1',
        type: 'text',
        label: 'Netfang #1',
        required: true,
        weight: 15,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_name_of_parent_2',
        type: 'text',
        label: 'Nafn foreldris/forráðamanns #2',
        required: true,
        weight: 16,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_simanumer_2',
        type: 'text',
        label: 'Símanúmer #2',
        required: true,
        weight: 17,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_netfang_2',
        type: 'text',
        label: 'Netfang #2',
        required: true,
        weight: 18,
        cols: 4
      })

      this.smarabioFields.push({
        key: 'waiver_child_heimilisfang',
        type: 'text',
        label: 'Heimilisfang',
        required: true,
        weight: 19,
        cols: 6
      })

      this.smarabioFields.push({
        key: 'waiver_child_gotuheiti',
        type: 'text',
        label: 'Götuheiti',
        required: true,
        weight: 20,
        cols: 6
      })

      this.smarabioFields.push({
        key: 'waiver_sveitafelag',
        type: 'text',
        label: 'Sveitafélag',
        required: true,
        weight: 21,
        cols: 6
      })

      this.smarabioFields.push({
        key: 'waiver_postal_code',
        type: 'text',
        label: 'Póstnúmer',
        required: true,
        weight: 22,
        cols: 6
      })
    },
    selectTime(value) {
      this.startDateAndTime = value
    },
    rebook() {
      this.$emit('rebook', this.booking)
    },
    getChannel(channelUUID) {
      const payload = {
        organization: this.$store.getters.organization.uuid,
        uuid: channelUUID
      }
      this.$store.dispatch('getChannelByUUID', payload).then(channel => {
        this.channel = channel
      })
    }
  }
}
</script>

<style scoped lang="sass">

</style>
