<template>
  <v-dialog
    persistent
    max-width="500px"
    :value="true"
  >
    <v-card>
      <v-card-title>
        {{ cardTitle }}
      </v-card-title>
      <v-card-subtitle
        class="pt-4"
        style="font-size: 1rem"
      >
        {{ cardSubTitle }}
      </v-card-subtitle>
      <v-card-actions>
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
          >
            <v-btn
              v-if="bookingHasEmail && validateEmail"
              color="error"
              class="font-weight-bold"
              block
              large
              :loading="processing"
              :disabled="processing"
              @click="changeBookingMethod(false)"
            >
              {{ $t('no') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-btn
              v-if="bookingHasEmail && validateEmail"
              color="primary"
              class="font-weight-bold"
              block
              large
              :loading="processing"
              :disabled="processing"
              @click="changeBookingMethod(true)"
            >
              {{ $t('yes') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-btn
              v-if="!bookingHasEmail || !validateEmail"
              color="primary"
              class="font-weight-bold"
              block
              large
              :loading="processing"
              :disabled="processing"
              @click="changeBookingMethod(false)"
            >
              {{ $t('continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotifyCustomerDialog",
  props: {
    processing: {
      type: Boolean
    }, 
    booking: {
      type: Object, 
      default: () => {}
    }
  },
  computed: {
    bookingHasEmail() {
      return this.booking.customer.email
    },
    validateEmail() {
      if(!this.bookingHasEmail) {
        return false
      }
      let re = /\S+@\S+\.\S+/
      return re.test(this.booking.customer.email)
    },
    cardTitle() {
      if(this.bookingHasEmail && this.validateEmail) {
        return this.$t('notifyCustomer')
      }
      return this.$t('canNotNotifyCustomerOfTheUpdate')
    },
    cardSubTitle() {
      if(this.bookingHasEmail && this.validateEmail) {
        return this.$t('wantToNotifyCustomerThatBookingBeenUpdated')
      }
      if(this.bookingHasEmail && !this.validateEmail) {
        return this.$t('invalidEmailAssociatedWithBooking')
      }
      return this.$t('thereIsNoEmailAssociatedWithThisBooking')
    },
  },
  methods: {
    changeBookingMethod(value) {
      this.$emit('changeBookingMethod', value)
    }
  }
}
</script>

<style scoped>

</style>
